import React from 'react';
import './Loading.css';

/**
 * Loading icon used in the application.
 * References are kept minimal to keep page initial load fast.
 */
const Loading = ({ style, ...other }) => (
    <div className="lds-default" style={style} {...other}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
);
Loading.defaultProps = {};

export default Loading;
