import 'react-app-polyfill/ie11';
import 'core-js/fn/array/find';
import 'core-js/fn/array/from';
import 'core-js/fn/array/includes';
import 'core-js/fn/string/includes';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/array/find-index';
import 'es7-object-polyfill';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loading from 'Components/FullScreenLoading/FullScreenLoading.jsx';

const App = lazy(() => import('./App'));

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Suspense fallback={<Loading />}>
        <App />
    </Suspense>,
    rootElement
);


