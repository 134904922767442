import React from 'react';
import Loading from '../Loading/Loading.jsx';

/**
 * Loading icon used in the application in the center of the screen. Useful for routing related loading.
 * References are kept minimal to keep page initial load fast.
 *
 */
const FullScreenLoading = ({ style, ...other }) => {
    return (
        <div style={Object.assign({}, styles.wrapper, style)} {...other}>
            <div style={styles.center}>
                <Loading />
            </div>
        </div>
    );
};

const styles = {
    wrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    },
    center: {
        position: 'absolute',
        left: '50%',
        top: '40%',
        width: 64,
        height: 64,
        transform: 'translate(-50%, -50%)'
    }
};

export default FullScreenLoading;
